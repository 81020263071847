/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Llamamos Síntoma a todo aquello que nos hace vivir una vida no plena.\nUsted puede venir a consulta por un dolor muscular que persiste desde hace más de 20 años y no encuentra alivio."), "\n", React.createElement(_components.p, null, "También puede llegar porque sus relaciones de pareja siempre terminan en infidelidad o separación, porque su economía está estancada, o porque no puede tener hijos."), "\n", React.createElement(_components.p, null, "O quizás porque tiene un hijo con alguna adicción, o usted mismo tiene alergias, fobias o ataques de pánico."), "\n", React.createElement(_components.p, null, "Los síntomas que se pueden trabajar en consulta son innumerables."), "\n", React.createElement(_components.p, null, "El “consultante” (la persona que llega a consulta), a veces tiene muy en claro que es aquello en su vida que no lo deja vivir en paz, asi como tambien hay personas no tienen muy en claro cual es su síntoma a trabajar, pero si tienen en claro que hay algo dentro de ellos que esta vacío y que sufre, sin importar que tan bien estan a nivel laboral, familiar o económico."), "\n", React.createElement(_components.p, null, "Esto ultimo tambien se trabaja en consulta, llegar al encuentro con el sintoma, es un camino muy revelador y hermoso.. aquello que tanto nos toca la puerta, cada dia, pero que sin embargo es invisible a nuestros ojos.. Porque reconocer el sintoma, es tambien un acto de valentia"), "\n", React.createElement(_components.p, null, "Bienvenid@s tod@s aquellos valientes."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 640px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 150.2439024390244%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB8So6xyBVQMYP/8QAFxABAQEBAAAAAAAAAAAAAAAAARAAEf/aAAgBAQABBQLcqEMwzP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CT//EABoQAAIDAQEAAAAAAAAAAAAAAAABEBEhMaH/2gAIAQEAAT8hQ8DQihi9hnfJtUv/2gAMAwEAAgADAAAAEDDgPv/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABYRAQEBAAAAAAAAAAAAAAAAAAEQQf/aAAgBAgEBPxBZs//EAB0QAQEBAQACAwEAAAAAAAAAAAERACFBUTFhcYH/2gAIAQEAAT8QAIKYexP66DFz72hl0wh4qnvMvxuwF+kyAIgj6l/dV6mWE8YJBdd//9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"el sintoma\"\n        title=\"el sintoma\"\n        src=\"/static/7812076edc1ca4c1c5fa91284a6a496f/bbe0c/el-sintoma.jpg\"\n        srcset=\"/static/7812076edc1ca4c1c5fa91284a6a496f/bd2b6/el-sintoma.jpg 205w,\n/static/7812076edc1ca4c1c5fa91284a6a496f/ceeba/el-sintoma.jpg 410w,\n/static/7812076edc1ca4c1c5fa91284a6a496f/bbe0c/el-sintoma.jpg 640w\"\n        sizes=\"(max-width: 640px) 100vw, 640px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
